import React from "react"

import EmptyDetailsWarningMessage from "./EmptyDetailsWarningMessage"
import requiredFields from "./utils/requiredFields.json"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faHome,
  faBuilding,
  faBirthdayCake,
  faIdCard,
} from "@fortawesome/free-solid-svg-icons"

const hasIncompleteValues = (data, flow) => {
  for (let i = 0; i < requiredFields[flow].length; i++) {
    let keys = requiredFields[flow][i].split(".")
    let finalValue = keys.reduce((object, key) => object[key], data)

    if (!finalValue) return true
  }
  return false
}

const PersonalDetailsSummary = ({ data, deliveryAddress, flow }) => {
  if (hasIncompleteValues(data, flow))
    return (
      <EmptyDetailsWarningMessage description="Some required information is missing or incomplete. Please double check if you have filled out the required fields." />
    )

  let {
    firstName,
    lastName,
    mobileNumber,
    viberNumber,
    birthday,
    emailAddress,
    employeeIDNumber,
    addressType,
    nps,
    programServiceRating,
  } = data

  const addressIcon = addressType === "Home" ? faHome : faBuilding

  return (
    <div className="px-1">
      {firstName && (
        <p>
          <span className="has-text-weight-bold">
            {firstName} {lastName}
          </span>
        </p>
      )}
      {mobileNumber && (
        <p>
          {mobileNumber} | {emailAddress}
        </p>
      )}
      {viberNumber !== "" ? (
        <p>Viber Number: {viberNumber}</p>
      ) : (
        <p>Viber Number: N/A</p>
      )}
      {deliveryAddress?.streetAddress && (
        <p>
          <span className="icon is-small">
            <FontAwesomeIcon icon={addressIcon} />
          </span>{" "}
          {deliveryAddress?.houseNumber
            ? `${deliveryAddress?.houseNumber} `
            : ""}
          {deliveryAddress?.streetAddress} {deliveryAddress?.barangay.value}{" "}
          {deliveryAddress?.city.value}, {deliveryAddress?.province?.value}
          {deliveryAddress?.notes && ` (${deliveryAddress.notes})`}
        </p>
      )}
      {birthday?.year?.value && (
        <p>
          <span className="icon is-small">
            <FontAwesomeIcon icon={faBirthdayCake} />
          </span>{" "}
          {birthday.month.value} {birthday.date.value}, {birthday.year?.value}
        </p>
      )}
      {employeeIDNumber && (
        <p>
          <span className="icon is-small">
            <FontAwesomeIcon icon={faIdCard} />
          </span>{" "}
          Employee ID #{employeeIDNumber}
        </p>
      )}
      {nps && <p>Feedback: {nps}</p>}
      {programServiceRating && (
        <p>Program Service Rating: {programServiceRating.value}</p>
      )}
    </div>
  )
}

export default PersonalDetailsSummary
