import moment from "moment"

export const formatDate = ({ date, format }) => {
  return moment(
    new Date(
      `${date?.month?.label.toString()} ${
        date?.date?.label.toString() ? `${date?.date?.label.toString()}, ` : ""
      }${date?.year.toString() ? `${date?.year?.label.toString()}` : ""}`
    )
  ).format(format)
}
