import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import Summary from "../../components/Epharmacy/Summary"

export default ({ location }) => (
  <Layout
    title="Order Summary"
    subtitle={
      <span>
        Please review your mobile number, delivery address, and order. We will
        verify your order via email. <br />
        Orders are considered final after checkout.
      </span>
    }
    seoTitle="Order Summary"
    process="epharmacy"
    display={{ footer: false, helpCenterBanner: false }}
    path={location?.pathname}
  >
    <Container isCentered desktop={6} fullhd={6}>
      <Summary backRoute="/epharmacy/checkout" />
    </Container>
  </Layout>
)
