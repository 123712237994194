import React from "react"
import classNames from "classnames"

const OrderTotal = ({ totalPrice }) => (
  <div className={classNames("is-size-5 has-text-grey has-text-left mt-1")}>
    Total Payment Due:{" "}
    <span className="ml-1 has-text-black has-text-weight-bold">
      Php {totalPrice}
    </span>
    <br />
    <span className="is-size-6">
      Order details will be confirmed and applied after verification via our
      pharmacy team.
    </span>
  </div>
)

export default OrderTotal
