import Resizer from "react-image-file-resizer"
import moment from "moment"
import {
  checkIfHasRxRequired,
  accumulatePrice,
} from "../../Epharmacy/services/computations"
import { zendeskUploadFiles } from "../../../services/zendeskService"
import { generateUploadFiles } from "../../../services/zendeskService"

const MINIMUM_SIZE_TO_COMPRESS = 1048576

const generateFileName = (format, docType, index) => {
  const type = docType.includes("EMPLOYEE") ? "employee" : "rx"
  // const fileExtension = format.split("/")[1]
  // return `${type}_${moment().format("YYYYMMDD_HHmmss")}.${fileExtension}`
  return `${type}_${moment().format("YYYYMMDD_HHmmss")}_${index}`
}

export const isPdf = (imgData) => {
  return (
    imgData?.substring("data:".length, imgData.indexOf(";base64")) ===
    "application/pdf"
  )
}

export const checkIfUploadDisabled = (flow, state) => {
  const hasNoDocuments = state.documents.length === 0
  switch (flow) {
    case "epharmacy":
      const MAX_ORDER_TOTAL_LIMIT = 1000
      return (
        hasNoDocuments &&
        (checkIfHasRxRequired(state[flow].medicines) ||
          parseFloat(accumulatePrice(state[flow].medicines)) >=
            MAX_ORDER_TOTAL_LIMIT)
      )
    // [] TO DO: remove this
    // case "flexmed":
    //   return hasNoDocuments
    default:
      return !state.orderingFor
  }
}

export const isAlreadyUploaded = (file, filesUploaded) => {
  return filesUploaded.some((uploaded) => file.oldname === uploaded.oldname)
}

// We need to specify docType as we're using upload in two components: upload docs and upload SCPWD ID
// In this case, it accepts the following values
//    - RX
//    - SCPWD_FRONT
//    - SCPWD_BACK

// Two things have docType as its dependency:
//    - The file's resulting fileName
//        - For RX, it becomes "doc_XXX"
//        - For both SCPWD, it becomes "scpwd_XXX"
//    - Components that display uploads, because some require both while some require the specific ID part e.g. front or back

export const uploadDocument = (
  file,
  filesUploaded,
  docType,
  setFilesUploaded,
  // callback,
  alreadyUploadedCallback,
  prefix,
  dispatch,
  index
) => {
  let reader = new FileReader()
  if (file.type === "application/pdf" || file.oldname?.includes("pdf")) {
    reader.readAsDataURL(file)
    reader.onload = () => {
      const newFile = {
        path: reader.result,
        name: generateFileName(file.type.split("/")[1], docType, index),
        filename: generateFileName(file.type.split("/")[1], docType, index),
        oldname: file.name,
        type: docType,
      }
      if (isAlreadyUploaded(newFile, filesUploaded) && alreadyUploadedCallback)
        alreadyUploadedCallback(newFile)
      else {
        setFilesUploaded((fileList) => {
          let previousFilesUploaded = [...fileList]
          previousFilesUploaded.push(newFile)
          // dispatch({
          //   type: "SAVE_DOCUMENTS",
          //   payload: [...previousFilesUploaded],
          // })
          // if (callback) callback(previousFilesUploaded, newFile)
          return previousFilesUploaded
        })
        setTimeout(async () => {
          const UPLOADED_FILES = await zendeskUploadFiles([newFile], null)
          const GENERATED_FILE_TOKEN = await generateUploadFiles(UPLOADED_FILES)
          setFilesUploaded((fileList) => {
            let previousFilesUploaded = [...fileList]
            let newFileList = [
              ...previousFilesUploaded.filter(
                (fileInFileList) => fileInFileList.oldname !== newFile.oldname
              ),
              {
                ...newFile,
                path: null, // Note: this removes the path from the file after uploading to Zendesk
                token: GENERATED_FILE_TOKEN[0],
              },
            ]
            dispatch({
              type: "SAVE_DOCUMENTS",
              payload: newFileList,
            })
            // if (callback) callback(newFileList)
            return newFileList
          })
          dispatch({
            type: "SHOW_TOAST",
            payload: {
              message: `${newFile?.oldname} has been uploaded.`,
              color: "success",
            },
          })
        }, 1000)
      }
    }
  } else
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      50,
      0,
      (uri) => {
        const newFile = {
          path: uri,
          name: generateFileName(file.type, docType, index),
          filename: generateFileName(file.type, docType, index),
          oldname: file.name,
          type: docType,
        }
        if (
          isAlreadyUploaded(newFile, filesUploaded) &&
          alreadyUploadedCallback
        )
          alreadyUploadedCallback(newFile)
        else {
          setFilesUploaded((fileList) => {
            let previousFilesUploaded = [...fileList]
            previousFilesUploaded.push(newFile)
            // dispatch({
            //   type: "SAVE_DOCUMENTS",
            //   payload: [...previousFilesUploaded],
            // })
            // if (callback) callback(previousFilesUploaded, newFile)
            return previousFilesUploaded
          })
          setTimeout(async () => {
            const UPLOADED_FILES = await zendeskUploadFiles([newFile], null)
            const GENERATED_FILE_TOKEN = await generateUploadFiles(
              UPLOADED_FILES
            )
            setFilesUploaded((fileList) => {
              let previousFilesUploaded = [...fileList]
              let newFileList = [
                ...previousFilesUploaded.filter(
                  (fileInFileList) => fileInFileList.oldname !== newFile.oldname
                ),
                {
                  ...newFile,
                  // path: null, // Note: this removes the path from the file after uploading to Zendesk
                  token: GENERATED_FILE_TOKEN[0],
                },
              ]
              for (let i = 0; i < newFileList.length; i++) {
                if (newFileList[i]?.oldname.includes("pdf")) {
                  newFileList[i].path = null
                }
              }
              dispatch({
                type: "SAVE_DOCUMENTS",
                payload: newFileList,
              })
              // if (callback) callback(newFileList)
              return newFileList
            })
            dispatch({
              type: "SHOW_TOAST",
              payload: {
                message: `${newFile?.oldname} has been uploaded.`,
                color: "success",
              },
            })
          }, 1000)
        }
      },
      "base64"
    )
}

export const hasFilesUploaded = (files) => {
  if (files.length > 0) return true
  else return false
}
