import React, { Fragment, useContext } from "react"
import classNames from "classnames"

import { Formik, Form } from "formik"

import Section from "../Elements/Section"
import FormCheckbox from "../Elements/Form/FormCheckbox"

import { consentAndAuthValidationSchema } from "./utils/formData"
import { AppContext } from "../../context/AppContext"
import styles from "./utils/summary.module.scss"

import data from "./utils/consentAndAuthorization.json"

const termsAndConditionBlurb = (
  <span>
    I agree to the <a href="/terms-and-conditions">terms and conditions</a>.
  </span>
)

const viberTermsAndConditionBlurb = (
  <span>
    I allow MedGrocer to reach out to me for outbound teleconsults and
    prescription renewal.
  </span>
)
const viberCommunicationBlurb = (
  <span>
    I allow MedGrocer to enroll me in the Viber community for future
    communication and announcements.
  </span>
)

const ConsentAndAuthSection = ({ title, data }) => (
  <Fragment>
    <h6 className="mt-0">{title}</h6>
    <ul className="help">
      {data.map((item) => (
        <li dangerouslySetInnerHTML={{ __html: item }} />
      ))}
    </ul>
  </Fragment>
)

const ConsentAndAuthorization = ({ children, flow, handleOnSubmit }) => {
  const { state, dispatch } = useContext(AppContext)

  return (
    <Section title="Consent and Authorization">
      <div className="mx-2">
        <article className="message">
          <div className={classNames("message-body", styles["collapsible"])}>
            <section className="content">
              {Object.keys(data).map((key) => (
                <ConsentAndAuthSection
                  title={data[key].title}
                  data={data[key].info}
                />
              ))}
            </section>
          </div>
        </article>
        <Formik
          initialValues={state[flow]}
          validationSchema={consentAndAuthValidationSchema}
          onSubmit={handleOnSubmit}
        >
          {({ values }) => (
            <Form>
              <FormCheckbox
                name="agreeToConsent"
                values={values.agreeToConsent}
                options={[
                  termsAndConditionBlurb,
                  viberTermsAndConditionBlurb,
                  viberCommunicationBlurb,
                ]}
                onChange={(event, newValue) => {
                  dispatch({
                    type: "SAVE_AGREE_CONSENT",
                    payload: [...newValue],
                  })
                }}
                hideOptional
              />
              {children}
            </Form>
          )}
        </Formik>
      </div>
    </Section>
  )
}

export default ConsentAndAuthorization
