import { generatePrice } from "../../services/computations"

export function orderTicketBody(request) {
  let {
    addressType,
    firstName,
    lastName,
    coupon,
    beneficiaryFirstName,
    beneficiaryLastName,
    beneficiaryType,
    beneficiaryOthers,
    emailAddress,
    mobileNumber,
    viberNumber,
    medicines,
    deliveryAddress,
    recipientMobileNumber,
    orderDetails,
    birthday,
    employeeIDNumber,
    orderNotes,
    personalEmail,
    designation,
    nps,
    agreeToViberConsent,
    prescribingHospital,
    prescribingDoctor,
    prcNumber,
    prescriptionDate,
    sex,
    programServiceRating,
    prescriptionPassword,
  } = request

  let medicinesArray = ""

  // medicines.map((medicine) => {
  //   medicinesArray += `${medicine.productID}, ${medicine.productTitle}, Qty: ${
  //     medicine.qty
  //   }, ₱${generatePrice(medicine)}, Intake per day: ${medicine.dosage}, Treatment Duration: ${medicine.duration} days\n`
  // })

  medicines.map((medicine) => {
    medicinesArray += `Product ID: ${medicine.productID} \nMedicine: ${
      medicine.productTitle
    } \nQty: ${medicine.qty}\nLine Total: ₱${generatePrice(
      medicine
    )} \nIntake per day: ${medicine.dailyIntake} \nIndication: ${
      medicine.indication
    } \nOthers: ${medicine.others}\n\n`
  })

  let userViberNumber = viberNumber
    ? `Viber Number: ${viberNumber}`
    : `Viber Number: N/A`
  let discountApplied = coupon.code.includes("PRIMARY") ? "100% off" : "50% off"

  return `Customer First Name: ${firstName}
  Customer Last Name: ${lastName}
  Beneficiary First Name: ${beneficiaryFirstName}
  Beneficiary Last Name: ${beneficiaryLastName}
  Beneficiary Type: ${beneficiaryType.type}
  Beneficiary Relationship: ${
    beneficiaryOthers ? beneficiaryOthers : beneficiaryType.label
  }
  Customer Email: ${emailAddress}
  Personal Email: ${personalEmail}
  Designation: ${designation}
  Contact Number: ${mobileNumber}
  ${userViberNumber}
  Recipient's Mobile Number: ${recipientMobileNumber}
  Address Type: ${addressType} 
  Street Address: ${
    deliveryAddress.houseNumber ? `${deliveryAddress.houseNumber} ` : ""
  }${deliveryAddress.streetAddress} 
  Barangay: ${
    deliveryAddress.barangay.value ? deliveryAddress.barangay.value : "NA"
  }
  City/Municipality: ${deliveryAddress.city.value}
  Province: ${deliveryAddress.province.value}  
  Birthday: ${birthday.month.value} ${birthday.date.value}, ${
    birthday.year.value
  }
  Sex: ${sex}
  Prescribing Hospital: ${prescribingHospital}
  Prescribing Doctor's Name: ${prescribingDoctor}
  Prescribing Doctor's PRC Number: ${prcNumber}
  Prescription Date: ${prescriptionDate.month.value} ${
    prescriptionDate.date.value
  }, ${prescriptionDate.year.value}
  Prescription Password: ${prescriptionPassword}
  Employee ID: ${employeeIDNumber}
  
  Total Ordered Medicines: ${medicines.length}

  ${medicinesArray}

  Medicines Total: ₱${parseFloat(orderDetails.total).toFixed(2)}
  Delivery Fee: ₱${orderDetails.deliveryFee.noDiscount}
  Subtotal: ₱${(
    parseFloat(orderDetails.total) +
    parseFloat(orderDetails.deliveryFee.noDiscount)
  ).toFixed(2)}
  Discount: ${discountApplied}
  Total: ₱${(
    parseFloat(orderDetails.subTotal) +
    parseFloat(orderDetails.deliveryFee.withDiscount)
  ).toFixed(2)}
  Order notes: ${orderNotes ? orderNotes : "NA"}
  Delivery notes: ${deliveryAddress?.notes ? deliveryAddress.notes : "NA"}
  Feedback: ${nps}
  Program Service Rating: ${programServiceRating.value}
  
  Viber Consent
  - I allow MedGrocer to reach out to me for outbound teleconsults and prescription renewal.\n- I allow MedGrocer to enroll me in the Viber community for future communication and announcements.
  `
}
