export const convertKeysToPascalCase = (obj) => {
  const _ = require("lodash")

  if (typeof obj !== "object" || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(convertKeysToPascalCase)
  }

  const convertedObj = {}
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      const pascalCaseKey = _.startCase(key).replace(/\s/g, "")
      convertedObj[pascalCaseKey] = obj[key]
    }
  }

  return convertedObj
}
