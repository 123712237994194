import React from "react"
import {
  DeliveryFeeRow,
  OrderSubtotalRow,
} from "./MedsOrderedSummary/MedicineInfoRow"

import OrderTotal from "./MedsOrderedSummary/OrderTotal"

const OrderSubtotalSummary = ({
  medicinesTotal,
  medicinesSubtotal,
  deliveryFee,
}) => {
  const subTotal = medicinesTotal
    ? (parseFloat(medicinesTotal) + deliveryFee.noDiscount).toFixed(2)
    : ""

  const finalTotal = medicinesSubtotal
    ? (parseFloat(medicinesSubtotal) + deliveryFee.withDiscount).toFixed(2)
    : ""
  return (
    <div className="px-1 mb-3">
      <DeliveryFeeRow deliveryFee={deliveryFee.noDiscount} />
      <OrderSubtotalRow orderSubtotal={subTotal} />
      <OrderTotal totalPrice={finalTotal} />
    </div>
  )
}

export default OrderSubtotalSummary
