export const shouldBeDisabled = (
  formData,
  medicines,
  documents,
  employeeId,
  loading
) => {
  // if hasPartnerRequest is true, get it as the condition (because it doesn't matter if medicines section has a data on it),
  // else use the medicines.length as condition (use the normal validation of medicines.length)

  let backEmployeeId = documents.filter(
    (file) => file.type === "EMPLOYEE_BACK"
  )?.[0]

  let frontEmployeeId = documents.filter(
    (file) => file.type === "EMPLOYEE_FRONT"
  )?.[0]

  return (
    (medicines.length === 0 && !formData.hasPartnerRequest) ||
    !formData.firstName ||
    !formData.deliveryAddress?.streetAddress ||
    formData.agreeToConsent.length < 3 ||
    documents.length === 0 ||
    !backEmployeeId ||
    !frontEmployeeId ||
    !formData?.employeeIDNumber ||
    loading
  )
}
