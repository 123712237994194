import { formatDate } from "../utils/formatDate"
import { convertKeysToPascalCase } from "../utils/convertKeysToPascalCase"

const getFlattenedStateValue = (stateValue) => {
  const isValueNotNull = stateValue !== null
  const isValueNotUndefined = stateValue !== undefined
  const isValueNotEmpty = stateValue !== ""
  const isValueArray = Array.isArray(stateValue)
  const isValueString = typeof stateValue === "string"
  const isValueNumber = typeof stateValue === "number"
  const isObjectHasValue = stateValue?.hasOwnProperty("value")

  if (isValueString || isObjectHasValue || isValueNumber) {
    return stateValue?.value || stateValue
  } else if (!isValueArray && isValueNotNull && isValueNotUndefined) {
    let stateValueObject = {}
    let stateValueKeys = Object.keys(stateValue)

    stateValueKeys.forEach((stateValueKey) => {
      stateValueObject[stateValueKey] = getFlattenedStateValue(
        stateValue[stateValueKey]
      )
    })

    return stateValueObject
  }
  return stateValue
}

export const parseInternalNote = ({ state }) => {
  let convertedState = convertKeysToPascalCase(state)
  let stateKeys = Object.keys(convertedState)
  let internalNote = {}

  stateKeys.forEach((key) => {
    if (convertedState[key]?.hasOwnProperty("city")) {
      internalNote.StreetAddress = `${
        !!convertedState[key].houseNumber
          ? `${convertedState[key].houseNumber} `
          : ""
      }${convertedState[key].streetAddress}`
      internalNote.City = `${convertedState[key].city?.value}`
      internalNote.Province = `${convertedState[key].province?.value}`
      internalNote.Barangay = `${convertedState[key].barangay?.value}`
      internalNote.DeliveryNotes = `${convertedState[key].notes || "N/A"}`
    }

    if (convertedState[key]?.hasOwnProperty("month")) {
      convertedState[key] = formatDate({
        date: convertedState[key],
        format: "ll",
      })
    }

    internalNote[key] = getFlattenedStateValue(convertedState[key])
  })

  internalNote["Total Ordered Medicines"] = convertedState.Medicines.length
  internalNote.Email = convertedState.PersonalEmail
  internalNote.NPS = convertedState.Nps
  internalNote.EmployeeID = convertedState.EmployeeIDNumber
  internalNote["Viber Number"] = convertedState.ViberNumber
  internalNote["Middle Name"] = convertedState.MiddleName
  internalNote["PRCNumber"] = convertedState.PrcNumber
  internalNote["Prescribing Doctor"] = convertedState.PrescribingDoctor
  internalNote["Prescribing Hospital"] = convertedState.PrescribingHospital

  delete internalNote?.AgreeToConsent
  delete internalNote?.Disclaimer
  delete internalNote?.EmployeeId
  delete internalNote?.Modal
  delete internalNote?.Toast
  delete internalNote?.Back
  delete internalNote?.Front
  delete internalNote?.Files

  return internalNote
}
