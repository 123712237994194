import { sendEmail } from "./sendEmail"
import { handleAddToFirestore } from "./handleAddToFirestore"
import { handleGetUserByEmail } from "./handleGetUserByEmail"
import { sendToZendesk, generateTemplate } from "./zendeskService"
// import { GATSBY_ORDER_ADDITIONAL_TAGS } from "gatsby-env-variables"
import { handleGetDefaultDocuments } from "./handleGetDefaultDocuments"
import { zendeskApi } from "./zendeskService"
import { parseInternalNote } from "../components/Epharmacy/services/parseInternalNote"

const generateTags = (flow, details) => {
  const templateTags = ["Website", "Viatris", "Order"]
  let subject, userConfirmationSubject
  if (flow.includes("epharmacy")) {
    templateTags.push("ePharmacy")
    subject = "[Viatris ePharmacy] Order from"
    userConfirmationSubject = "Your Viatris Order"
    if (details?.hasPartnerRequest) templateTags.push("partner_request")
  }
  if (process.env.GATSBY_ENV !== "production") templateTags.push("test")
  return {
    tags: [
      ...templateTags,
      ...(process.env.GATSBY_ORDER_ADDITIONAL_TAGS || []),
    ],
    subject,
    userConfirmationSubject,
  }
}

const arrayBufferToBase64 = (buffer) => {
  return btoa(String.fromCharCode(...new Uint8Array(buffer)))
}

const importPublicKey = async () => {
  const pem = process.env.GATSBY_FILE_PW_PUBLIC_KEY.replace(/\\n/g, "\n")
  const b64 = pem
    .replace(/-----(BEGIN|END) PUBLIC KEY-----/g, "")
    .replace(/\s+/g, "")
  const keyData = Uint8Array.from(atob(b64), (c) => c.charCodeAt(0))

  return await crypto.subtle.importKey(
    "spki",
    keyData,
    { name: "RSA-OAEP", hash: "SHA-256" },
    true,
    ["encrypt"]
  )
}

const encryptData = async (data) => {
  const encoder = new TextEncoder()
  const encodedData = encoder.encode(data)

  const publicKey = await importPublicKey()

  const encryptedData = await crypto.subtle.encrypt(
    {
      name: "RSA-OAEP",
    },
    publicKey,
    encodedData
  )

  const encryptedByteArr = new Uint8Array(encryptedData)

  return arrayBufferToBase64(encryptedByteArr)
}

export const sendRequest = async (
  flow, // process ? flexmed or epharmacy
  details, // state
  fileArray, // list of files
  templateConfirmation, // email template
  templateZendesk, // zendesk template
  callback, // success func
  errorCallback // error func
) => {
  // encrypt the password if it's not empty / N/A
  if (
    details?.epharmacy?.prescriptionPassword !== "" &&
    details?.epharmacy?.prescriptionPassword !== "N/A"
  ) {
    const encPW = await encryptData(details?.epharmacy?.prescriptionPassword)

    // update the details to store the encrypted password instead
    details = {
      ...details,
      epharmacy: {
        ...details?.epharmacy,
        prescriptionPassword: encPW,
      },
    }
  } else {
    details = {
      ...details,
      epharmacy: {
        ...details?.epharmacy,
        prescriptionPassword: "N/A",
      },
    }
  }
  let templateObjects = { ...details[flow] }
  const { emailAddress, coupon, medicines } = templateObjects
  const { tags, subject, userConfirmationSubject } = generateTags(
    flow,
    details[flow]
  )
  templateObjects = { ...templateObjects, files: fileArray }
  const requestTemplate = generateTemplate(
    subject,
    emailAddress,
    templateZendesk,
    templateObjects,
    tags
  )

  let internalNote = parseInternalNote({ state: templateObjects })

  try {
    const response = await sendToZendesk(requestTemplate, fileArray)
    try {
      await zendeskApi().put(`/tickets/${response.data.request.id}.json`, {
        ticket: {
          comment: {
            body: `For IT use:\n\n${JSON.stringify(internalNote)}`,
            public: false,
          },
        },
      })
    } catch (error) {}

    // let user = await handleGetUserByEmail({ email: emailAddress })

    // if (!user) {
    //   user = await handleAddToFirestore({
    //     path: [{ type: "collection", path: "users" }],
    //     document: handleGetDefaultDocuments({
    //       type: "user",
    //       document: { ...templateObjects },
    //     }),
    //   })
    // }

    // let orderDocument = await handleAddToFirestore({
    //   path: [
    //     { type: "collection", path: "users" },
    //     { type: "document", path: user.id },
    //     { type: "collection", path: "orders" },
    //   ],
    //   document: handleGetDefaultDocuments({
    //     type: "order",
    //     document: { ...templateObjects, zendeskId: response.data.request.id },
    //   }),
    // })

    // for (const medicine of medicines) {
    //   await handleAddToFirestore({
    //     path: [
    //       { type: "collection", path: "users" },
    //       { type: "document", path: user.id },
    //       { type: "collection", path: "orders" },
    //       { type: "document", path: orderDocument?.id },
    //       { type: "collection", path: "medicines" },
    //     ],
    //     document: handleGetDefaultDocuments({
    //       type: "medicine",
    //       document: medicine,
    //     }),
    //   })
    // }

    callback(emailAddress)
  } catch (error) {
    errorCallback()
  }
}
